import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../layouts'
import SEO from '../components/SEO'
import Base64TextDecoder from '../components/Base64TextDecoder'
import Container from '../components/Container'
import PostListHorizontal from '../components/PostListHorizontal'
import PostCard from '../components/PostCard'
import Adsense from '../components/Adsense'
import Disqus from '../components/Disqus'
import Share from '../components/Share'

import './index.scss'

class Index extends React.Component {
  render() {
    const posts = get(this, 'props.data.allMarkdownRemark.edges')
    const partial = this.props.data.markdownRemark

    return (
      <Layout
        location={this.props.location}
        siteMeta={this.props.data.site.siteMetadata}
      >
        <SEO article={false} />
        <Base64TextDecoder siteMeta={this.props.data.site.siteMetadata} />
        <div className="home-ad">
          <Adsense type="article-top-responsive" />
        </div>
        <div className="home-blog-section">
          <Container>
            <h2 className="blog-latest-title">Latest From The Blog</h2>
            <div className="clearfix">
              <div className="blog-featured">
                <PostCard
                  post={posts[0].node}
                  type="featured"
                  siteMeta={this.props.data.site.siteMetadata}
                />
              </div>
              <div className="blog-latest">
                <PostListHorizontal
                  posts={posts.slice(1, 5)}
                  siteMeta={this.props.data.site.siteMetadata}
                />
              </div>
            </div>
            <div className="home-ad">
              <Adsense type="article-bottom-responsive" />
            </div>
            <div className="blog-about">
              <h2 className="blog-about-title">
                About {this.props.data.site.siteMetadata.name}
              </h2>
              <div
                className="info-group clearfix"
                dangerouslySetInnerHTML={{ __html: partial.html }}
              />
            </div>
          </Container>
        </div>
        <div className="home-ad">
          <Adsense type="article-bottom-responsive" />
        </div>
        <Container>
          <div className="page-share">
            <Share
              title={this.props.data.site.siteMetadata.title}
              pathname={this.props.location.pathname}
            />
          </div>
        </Container>
        <Container>
          <div className="page-comments">
            <Disqus
              location={this.props.location}
              disqusShortname={
                this.props.data.site.siteMetadata.disqusShortname
              }
            />
          </div>
        </Container>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        disqusShortname
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { eq: "post" } } }
    ) {
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            type
            description
            featured_image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    markdownRemark(
      frontmatter: { type: { eq: "partial" }, name: { eq: "home" } }
    ) {
      html
    }
  }
`
