import React from 'react'
import Container from '../Container'
import { atobUTF8 } from '../../util'

import './Base64TextDecoder.scss'

class Base64TextDecoder extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      output: ''
    }
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    if(window.innerWidth > 992) {
      this.inputRef.current.focus()
    }
  }

  handleInputChange = event => {
    const value = event.target.value
    try {
      const encodedValue = atobUTF8(value)
      this.setState({
        output: encodedValue,
        invalid: false
      })
    } catch (err) {
      this.setState({
        output: '',
        invalid: true
      })
    }
  }

  copyToClipboard = event => {
    if (!event.target.value) {
      return
    }

    this.selectArea(event)

    if (document.queryCommandSupported('copy')) {
      document.execCommand('copy')
    }
  };

  selectArea = event => {
    if (event.target.value) {
      event.target.select()
    }
  };

  render () {
    return (
      <div className="product">
        <Container>
          <div className="product-form">
            <h1 className="product-heading">Base64 Decode online</h1>
            <form id="url-encoder-form">
              <div className={'form-item ' + (this.state.invalid ? 'invalid' : '')}>
                <textarea className="form-control input-area"
                  placeholder="Type or paste your base64 encoded string here..."
                  aria-label="InputString"
                  ref={this.inputRef}
                  onChange={this.handleInputChange}></textarea>
                  {this.state.invalid ? (
                  <div className="error">
                    That's not a valid Base64 encoded string
                  </div>
                ) : null}
              </div>
              <div className="form-item">
                <textarea className="form-control output-area"
                  placeholder="Base64 decoded output goes here..."
                  aria-label="Output"
                  readOnly
                  onFocus={this.selectArea}
                  onClick={this.copyToClipboard}
                  value={this.state.output}></textarea>
              </div>
              <div className="info">
                <span>Note: The tool uses <code>UTF-8</code> charset.</span>
                <span className="bug">
                  Found a bug? <a href="mailto:rajeevhub@gmail.com?subject=Bug in Base64 Decoder">report!</a>
                </span>
              </div>
            </form>
          </div>
        </Container>
      </div>
    )
  }
}

export default Base64TextDecoder
